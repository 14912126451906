import React, {useEffect, useState} from "react";

import sun from './../img/sun.png';

import Footer from './Footer';
import ObtainedPointsModal from './ObtainedPointsModal';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function EventScreen(props) {  
    const [eventData, setEventData] = useState({
        eventTitle: "",
        eventDescription: ""
    });

    let points = props.event.points;

    const loadLanguage = () => {
        var appLanguage = localStorage.getItem("lang");
        if(!appLanguage){
          appLanguage = "es";
        }
        return appLanguage;
    }

    const [pointsModalShow, setPointsModalShow] = useState(false);

    const [pointsModalData, setPointsModalData] = useState({
        points: [],
        optionNumber: 0
    });

    let appLanguage = loadLanguage();

    // Similar to componentDidMount and componentDidUpdate:  
    useEffect(() => {    
        if(appLanguage === "es"){
            setEventData({
                eventTitle: props.event.title.es,
                eventDescription: props.event.description.es
            });
        } else if(appLanguage === "eus"){
            setEventData({
                eventTitle: props.event.title.eus,
                eventDescription: props.event.description.eus
            });
        } else {
            setEventData({
                eventTitle: props.event.title.en,
                eventDescription: props.event.description.en
            });
        }        
    }, [appLanguage, props.event]);

    return (
        <div className="App">
            <header className="App-intro-header">
                <img src={sun} className="App-logo-intro" alt="logo" />
            </header>
            
            <label className="Event-label">{i18next.t("Event")}</label>
            <div className="Event-info-div">
                <label className="Event-title">{eventData.eventTitle}</label>
                <p className="Event-description">{eventData.eventDescription}</p>
                <button className="Event-continue-button" onClick={() => handleOnContinueClick(points)}>{i18next.t("Continue")}</button>
            </div>
            
            <Footer></Footer>
            
            <ObtainedPointsModal
                show = { pointsModalShow }
                points = { pointsModalData.points }
                onHide = { (points, number) => handleOnHideEventScreen(points, number) }
            />
        </div>
    );

    function handleOnContinueClick(points, number){
        setPointsModalData({
            points: points,
            optionNumber: number
        });
        setPointsModalShow(true);
    }

    function handleOnHideEventScreen(){
        setPointsModalShow(false);
        props.onClick(pointsModalData.points);
    }
}

export default withTranslation()(EventScreen);