import React from 'react';
import './App.css';
import {Routes, Route/*, useNavigate*/} from 'react-router-dom';

import HomeScreen from './screens/HomeScreen';
import IntroScreen from './screens/IntroScreen';
import ProfileScreen from './screens/ProfileScreen';
import GameScreen from './screens/GameScreen';

function App() {
  return (    
    <Routes>
      <Route path="/intro" element={<IntroScreen />} />
      <Route path="/profile" element={<ProfileScreen />} />
      <Route path="/game" element={<GameScreen />} />
      <Route path="*" element={<HomeScreen />} />
    </Routes>
  ) 
}

export default App;
