import React, {useState, useEffect} from "react";

import UserActionScore from "./UserActionScore";

import crowd from './../img/crowd.png';
import forest from './../img/forest.png';
import economy from './../img/economy.png';
import innovation from './../img/innovation.png';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function GameActionInfo(props) {
    const [actionType, setActionType] = useState("");

    /*let eventTitle = useRef("");
    let eventDescription = useRef("");

    let dilemaTitle = useRef("");
    let dilemaDescription = useRef("");
    let selectedOptionText = useRef("");*/

    const [eventData, setEventData] = useState({
        eventTitle: "",
        eventDescription: ""
    });

    const [dilemaData, setDilemaData] = useState({
        dilemaTitle: "",
        dilemaDescription: "",
        selectedOptionText: "",
        selectedOptionInformation: "",
        colorStyle: {
            color: "#fff"
        }
    });

    const [points, setPoints] = useState({
        environment: 0,
        society: 0,
        innovation: 0,
        economy: 0
    });

    var appLanguage = localStorage.getItem("lang");

    // Similar to componentDidMount and componentDidUpdate:  
    useEffect(() => {  
        if(props.action != null){
            setActionType(props.action.actionType);
            
            if(props.action.actionType === "event"){  
                if(appLanguage === "es"){
                    setEventData({
                        eventTitle: props.action.title.es,
                        eventDescription: props.action.description.es
                    });
                } else if(appLanguage === "eus"){
                    setEventData({
                        eventTitle: props.action.title.eus,
                        eventDescription: props.action.description.eus
                    });
                } else {
                    setEventData({
                        eventTitle: props.action.title.en,
                        eventDescription: props.action.description.en
                    });
                }   

                setPoints(props.action.points);
            } else if(props.action.actionType === "dilema"){   
                let optionIndex = props.action.selectedOptionNumber - 1;
                if(appLanguage === "es"){
                    setDilemaData({
                        dilemaTitle: props.action.title.es,
                        dilemaDescription: props.action.description.es,
                        selectedOptionText: props.action.options[optionIndex].description.es,
                        selectedOptionInformation: props.action.options[optionIndex].information.es,
                        colorStyle: {
                            color: props.action.color
                        }
                    });
                } else if(appLanguage === "eus"){
                    setDilemaData({
                        dilemaTitle: props.action.title.eus,
                        dilemaDescription: props.action.description.eus,
                        selectedOptionText: props.action.options[optionIndex].description.eus,
                        selectedOptionInformation: props.action.options[optionIndex].information.eus,
                        colorStyle: {
                            color: props.action.color
                        }
                    });
                } else {
                    setDilemaData({
                        dilemaTitle: props.action.title.en,
                        dilemaDescription: props.action.description.en,
                        selectedOptionText: props.action.options[optionIndex].description.en,
                        selectedOptionInformation: props.action.options[optionIndex].information.en,
                        colorStyle: {
                            color: props.action.color
                        }
                    });
                }   

                setPoints(props.action.options[optionIndex].points);
            }
        }
    }, [props.action, actionType, appLanguage, points]);

    const dilemaDivStyle = {
        marginBottom: '20px',
        zIndex: "5"
    }

    const eventDivStyle = {
        zIndex: "5"
    }

    const eventTitleTextStyle = {
        fontSize: "22px"
    }

    const eventInfoMarginTopStyle = {
        marginTop: "20px"
    }

    const scoreRowDivStyle = {
        display: "flex"
    }

    const eventInfoDivStyle = {
        marginTop: "10px"
    }

    const eventDescriptionStyle = {
        paddingLeft: "20px",
        paddingRight: "20px",
        zIndex: "999"
    }

    if(actionType === "event"){
        return (
            <div style={eventDivStyle}>
                <label className="Dilema-label">{i18next.t("Event")}</label>
                <div className="Event-info-div" style={eventInfoDivStyle}>
                    <label className="Event-title" style={eventTitleTextStyle}>{eventData.eventTitle}</label>
                    <div className="App" style={eventInfoMarginTopStyle}>
                        <div style={scoreRowDivStyle}>
                            <UserActionScore 
                                icon={forest} 
                                points={points.environment}
                                color="#1B5F2A"
                                height="40px"
                                rowHeight="50px"
                            ></UserActionScore>
                            <UserActionScore 
                                icon={crowd} 
                                points={points.society}
                                color="#8B1772"
                                height="40px"
                                rowHeight="50px"
                            ></UserActionScore>
                        </div>
                        <div style={scoreRowDivStyle}>
                            <UserActionScore 
                                icon={innovation} 
                                points={points.innovation}
                                color="#877C16"
                                height="40px"
                                rowHeight="50px"
                            ></UserActionScore>
                            <UserActionScore 
                                icon={economy} 
                                points={points.economy}
                                color="#1B16FF"
                                height="40px"
                                rowHeight="50px"
                            ></UserActionScore>
                        </div>
                    </div>                    
                    <p className="Event-description">{eventData.eventDescription}</p>
                </div>
            </div>
        )
    } else if(actionType === "dilema"){
        return (
            <div class="App" style={dilemaDivStyle}>
                <label className="Dilema-label-modal">{i18next.t("Dilemma")}</label>
                <label className="Dilema-category" style={dilemaData.colorStyle}>{dilemaData.dilemaTitle}</label>

                <p className="Dilema-question">{dilemaData.dilemaDescription}</p>

                <div className="Dilema-option-modal-text-div"><div className="Dilema-option-text">{dilemaData.selectedOptionText}</div></div>

                <div className="App">
                    <div style={scoreRowDivStyle}>
                        <UserActionScore 
                            icon={forest} 
                            points={points.environment}
                            color="#1B5F2A"
                            height="40px"
                            rowHeight="50px"
                        ></UserActionScore>
                        <UserActionScore 
                            icon={crowd} 
                            points={points.society}
                            color="#8B1772"
                            height="40px"
                            rowHeight="50px"
                        ></UserActionScore>
                    </div>
                    <div style={scoreRowDivStyle}>
                        <UserActionScore 
                            icon={innovation} 
                            points={points.innovation}
                            color="#877C16"
                            height="40px"
                            rowHeight="50px"
                        ></UserActionScore>
                        <UserActionScore 
                            icon={economy} 
                            points={points.economy}
                            color="#1B16FF"
                            height="40px"
                            rowHeight="50px"
                        ></UserActionScore>
                    </div>
                </div>
                <p className="Event-description" style={eventDescriptionStyle}>{dilemaData.selectedOptionInformation}</p>
            </div>
        )
    }
}

export default withTranslation()(GameActionInfo);