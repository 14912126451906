import React, {useEffect, useState} from "react";

import Footer from './Footer';

import crowd from './../img/crowd.png';
import forest from './../img/forest.png';
import economy from './../img/economy.png';
import innovation from './../img/innovation.png';

import question from './../img/question.png';
import exclamation from './../img/exclamation.png';
import refresh from './../img/refresh.png';

import GameScope from './GameScope';
import GameAction from './GameActions';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import UserActionsModal from "./UserActionsModal";

function GameProcess(props){    
    const updatingMilliseconds = 1000;

    const [displayStyle, setDisplayStyle] = useState({
        dilemaDisplayStyle: {display: "none"},
        eventDisplayStyle: {display: "none"},
        updatingDisplayStyle: {display: "none"}
    });

    const [score, setScore] = useState({
        environment: 0,
        society: 0,
        innovation: 0,
        economy: 0
    });
    
    useEffect(() => {        
        const selectDisplayAction = () => {
            if(props.action === "dilema"){
                setDisplayStyle({
                    dilemaDisplayStyle: {display: "inherit"},
                    eventDisplayStyle: {display: "none"},
                    updatingDisplayStyle: {display: "none"}
                });
            } else {
                setDisplayStyle({
                    dilemaDisplayStyle: {display: "none"},
                    eventDisplayStyle: {display: "inherit"},
                    updatingDisplayStyle: {display: "none"}
                });
            }
        }

        const setLastScore = () => {
            setScore({
                environment: props.score.environment - props.lastActionPoints.environment,
                society: props.score.society - props.lastActionPoints.society,
                innovation: props.score.innovation - props.lastActionPoints.innovation,
                economy: props.score.economy - props.lastActionPoints.economy
            });            
        }

        const updateScore = () => {
            setScore(props.score);
        }

        const launchUpdatingTimeout = () => {
            const timer = setTimeout(() => {
                selectDisplayAction();
            }, updatingMilliseconds);
            return () => clearTimeout(timer);
        }

        if(props.actionsCount === 0){
            setScore(props.score);
            selectDisplayAction();
        } else {
            setDisplayStyle({
                dilemaDisplayStyle: {display: "none"},
                eventDisplayStyle: {display: "none"},
                updatingDisplayStyle: {display: "inherit"}
            });
            setLastScore();
            updateScore();
            launchUpdatingTimeout();
        }
    }, [props.action, props.actionsCount, props.lastActionPoints.economy, props.lastActionPoints.environment, props.lastActionPoints.innovation, props.lastActionPoints.society, props.score, score]);

    return(
        <div className="App">
            <UserActionsModal actionsCount={props.actionsCount} userActionList={props.userActionList} last={true} resultScreen={false}></UserActionsModal>

            <GameAction value={props.actionsCount+1} actions={props.totalActions} controls={"none"}></GameAction>

            <div className='App-scopes-div'>
                <div className="Society-scope">
                    <GameScope color="#8B1772" icon={crowd} title={i18next.t("Society")} value={score.society} position="left" location="top"></GameScope>
                </div>
                <div className="Environment-scope">
                    <GameScope color="#1B5F2A" icon={forest} title={i18next.t("Environment")} value={score.environment} position="right" location="top"></GameScope>
                </div>
                <div className="Economy-scope">
                    <GameScope color="#1B16FF" icon={economy} title={i18next.t("Economy")} value={score.economy} position="left" location="bottom"></GameScope>
                </div>
                <div className="Innovation-scope">
                    <GameScope color="#877C16" icon={innovation} title={i18next.t("Innovation")} value={score.innovation} position="right" location="bottom"></GameScope> 
                </div>             
            </div>

            <button className='Dilema-circle' style={displayStyle.dilemaDisplayStyle} onClick={(event) => props.onClick("dilema")}>
                <div className='Action-circle-div-element'>
                    <div>
                        <img className="Action-mark" src={question} alt=""></img>
                    </div>
                    <div>
                        <label className="Action-text">{i18next.t("New dilemma")}</label>
                    </div>
                </div>
            </button>

            <button className='Event-circle' style={displayStyle.eventDisplayStyle} onClick={(event) => props.onClick("event")}>
                <div className='Action-circle-div-element'>
                    <div>
                        <img className="Action-mark" src={exclamation} alt=""></img>
                    </div>
                    <div>
                        <label className="Action-text">{i18next.t("New event")}</label>
                    </div>
                </div>
            </button>

            <div className="Updating-icon-div" style={displayStyle.updatingDisplayStyle}>
                <img src={refresh} className="Updating-icon" alt=""></img>
            </div>

            <button style={displayStyle.dilemaDisplayStyle} className="Game-dilema-event-button" onClick={(event) => props.onClick("dilema")}>                              
                {i18next.t("New dilemma ahead")}
            </button>

            <button style={displayStyle.eventDisplayStyle} className="Game-dilema-event-button" onClick={(event) => props.onClick("event")}>                
                {i18next.t("An event has occurred")}
            </button>
            
            <Footer></Footer>
        </div>
    )
}

export default withTranslation()(GameProcess);