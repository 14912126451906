import React, {useState, useEffect} from "react";

import DilemaOption from "./DilemaOption";
import DilemaOptionInfo from "./DilemaOptionInfo";
import Footer from './Footer';
import ObtainedPointsModal from './ObtainedPointsModal';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function DilemaScreen(props) {
    const [info, setInfo] = useState({
        active: false,
        number: 0, 
        text: ""
    });

    const [dilemaData, setDilemaData] = useState({
        dilemaTitle: "",
        dilemaDescription: "",
        dilemaOptions: {
            option1: {},
            option2: {},
            option3: {}
        }
    });

    const [pointsModalShow, setPointsModalShow] = useState(false);

    const [pointsModalData, setPointsModalData] = useState({
        points: [],
        optionNumber: 0
    });

    const loadLanguage = () => {
        var appLanguage = localStorage.getItem("lang");
        if(!appLanguage){
          appLanguage = "es";
        }
        return appLanguage;
    }

    let appLanguage = loadLanguage();
    
    const dilemaColor = props.dilema.color;
    const titleColorStyle = {
        color: dilemaColor
    }

    // Similar to componentDidMount and componentDidUpdate:  
    useEffect(() => {    
        if(appLanguage === "es"){
            setDilemaData({
                dilemaTitle: props.dilema.title.es,
                dilemaDescription: props.dilema.description.es,
                dilemaOptions: {
                    option1: {
                        description: props.dilema.options[0].description.es,
                        information: props.dilema.options[0].information.es
                    },
                    option2: {
                        description: props.dilema.options[1].description.es,
                        information: props.dilema.options[1].information.es
                    },
                    option3: {
                        description: props.dilema.options[2].description.es,
                        information: props.dilema.options[2].information.es
                    }
                }
            });
        } else if(appLanguage === "eus"){
            setDilemaData({
                dilemaTitle: props.dilema.title.eus,
                dilemaDescription: props.dilema.description.eus,
                dilemaOptions: {
                    option1: {
                        description: props.dilema.options[0].description.eus,
                        information: props.dilema.options[0].information.eus
                    },
                    option2: {
                        description: props.dilema.options[1].description.eus,
                        information: props.dilema.options[1].information.eus
                    },
                    option3: {
                        description: props.dilema.options[2].description.eus,
                        information: props.dilema.options[2].information.eus
                    }
                }
            });
        } else {
            setDilemaData({
                dilemaTitle: props.dilema.title.en,
                dilemaDescription: props.dilema.description.en,
                dilemaOptions: {
                    option1: {
                        description: props.dilema.options[0].description.en,
                        information: props.dilema.options[0].information.en
                    },
                    option2: {
                        description: props.dilema.options[1].description.en,
                        information: props.dilema.options[1].information.en
                    },
                    option3: {
                        description: props.dilema.options[2].description.en,
                        information: props.dilema.options[2].information.en
                    }
                }
            });
        }      
        
        console.log("Dilema screen");
        console.log(props.dilema);
    }, [appLanguage, props.dilema]);

    return (
        <div className="App">
            <label className="Dilema-label">{i18next.t("Dilemma")}</label>
            <label className="Dilema-category" style={titleColorStyle}>{dilemaData.dilemaTitle}</label>

            <p className="Dilema-question">{dilemaData.dilemaDescription}</p>

            <DilemaOption 
                number="1" 
                text={dilemaData.dilemaOptions.option1.description} 
                points={props.dilema.options[0].points}
                onClick={(points, number) => handleOnOptionClick(points, 1)}
                onAuxClick={(event) => handleOnOptionInfoClick(event)}
            >                
            </DilemaOption>
            <DilemaOption 
                number="2" 
                text={dilemaData.dilemaOptions.option2.description}
                points={props.dilema.options[1].points}
                onClick={(points, number) => handleOnOptionClick(points, 2)}
                onAuxClick={(event) => handleOnOptionInfoClick(event)}
            >                
            </DilemaOption>
            <DilemaOption 
                number="3" 
                text={dilemaData.dilemaOptions.option3.description} 
                points={props.dilema.options[2].points}
                onClick={(points, number) => handleOnOptionClick(points, 3)}
                onAuxClick={(event) => handleOnOptionInfoClick(event)}
            >                
            </DilemaOption>

            <DilemaOptionInfo 
                active={info.active}
                number={info.number}
                text={info.text}
            >                
            </DilemaOptionInfo>
            
            <Footer></Footer>               

            <ObtainedPointsModal
                show = { pointsModalShow }
                points = { pointsModalData.points }
                onHide = { (points, number) => handleOnHideDilemaScreen(points, number) }
            />
        </div>
    );

    function handleOnOptionClick(points, number){
        setPointsModalData({
            points: points,
            optionNumber: number
        });
        setPointsModalShow(true);
    }

    function handleOnHideDilemaScreen(){
        setPointsModalShow(false);
        props.onClick(pointsModalData.points, pointsModalData.optionNumber);
    }

    function handleOnOptionInfoClick(number){
        let text;
        if(number === "1"){
            text = dilemaData.dilemaOptions.option1.information;
        } else if(number === "2"){
            text = dilemaData.dilemaOptions.option2.information;
        } else {
            text = dilemaData.dilemaOptions.option3.information;
        }

        setInfo({
            active: 1, 
            number: number,
            text: text
        });
    }
}

export default withTranslation()(DilemaScreen);