import React from 'react';
import pregunta from './../img/pregunta.png';

function QuestionIcon(props) {
    if(props.activate === true){
        return (
            <div>
                <img src={pregunta} alt="icon" className='Intro-question-icon'/>
            </div>
        )
    }
}

export default QuestionIcon;