import React from 'react';
import thinking from './../img/thinking.png';

function ThinkingIcon(props) {
    if(props.activate === true){
        return (
            <div>
                <img src={thinking} alt="icon" className='Intro-thinking-icon'/>
            </div>
        )
    }
}

export default ThinkingIcon;