import React, {useState, useEffect, useRef} from "react";

import Modal from 'react-modal';
import { Link } from "react-router-dom";

import UserActions from './UserActions';
import actionsInfo from './../img/actions-info.png';
import finishIcon from './../img/banderas.png';

function UserActionsModal(props){
    const [modalIsOpen, setIsOpen] = useState(false);

    const displayUserActionsIcon = useRef("none");

    const [actionData, setActionData] = useState({
        userActionList: [],
        actionsCount: 0,
        last: false
    }); 
    
    useEffect(() => {
        if(props.userActionList && props.userActionList.length > 0) {
            displayUserActionsIcon.current = "inherit";
        } 
    
        setActionData({
            userActionList: props.userActionList,
            actionsCount: props.actionsCount,
            last: props.last
        });
    }, [props.actionsCount, props.userActionList, props.last]);

    const actionsIconDisplayStyle = {
        display: displayUserActionsIcon.current
    };
    
    const modalCustomStyles = {
        content: {
            height: '100%',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        },
        overlay: {zIndex: 1000}
    };

    let finishIconDisplayStyle = {display: "none"};
    if(props.resultScreen){
        finishIconDisplayStyle = {display: "initial"};
    }

    return (
        <>
            <img src={actionsInfo} className="Game-actions-info-icon" alt="" onClick={openModal} style={actionsIconDisplayStyle}/>
            <Link to="/"><img src={finishIcon} className="Game-finish-icon" alt="" onClick={openModal} style={finishIconDisplayStyle}/></Link>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={modalCustomStyles}
                contentLabel="Actions Modal"
                ariaHideApp={false}
            >
                <UserActions onClick={closeModal} actionsCount={actionData.actionsCount} userActionList={actionData.userActionList} last={actionData.last}></UserActions>
            </Modal>
        </>
    )
    
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal(){
        
    }

    function closeModal() {
        setIsOpen(false);
    }
}

export default UserActionsModal;