import React from "react";

import atelier from './../img/atelier.jpg';
import deustotech from './../img/deustotech.png';

import crowd from './../img/crowd.png';
import forest from './../img/forest.png';
import economy from './../img/economy.png';
import innovation from './../img/innovation.png';

import ScopeFinalPoints from "./ScopeFinalPoints";
import UserActionsModal from "./UserActionsModal";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function GameOverScreen(props) {
    return (
        <div className="App">
            <UserActionsModal actionsCount={props.actionsCount} userActionList={props.userActionList} last={false} resultScreen={false}></UserActionsModal>

            <label className="Game-over-label">{i18next.t("Game over")}</label>

            <ScopeFinalPoints 
                title={i18next.t("Environment")} 
                icon={forest} 
                points={props.score.environment}
                color="#1B5F2A"
                height="55px"
                rowHeight="70px"
            ></ScopeFinalPoints>
            <ScopeFinalPoints 
                title={i18next.t("Society")} 
                icon={crowd} 
                points={props.score.society}
                color="#8B1772"
                height="55px"
                rowHeight="70px"
            ></ScopeFinalPoints>
            <ScopeFinalPoints 
                title={i18next.t("Innovation")} 
                icon={innovation} 
                points={props.score.innovation}
                color="#877C16"
                height="55px"
                rowHeight="70px"
            ></ScopeFinalPoints>
            <ScopeFinalPoints 
                title={i18next.t("Economy")} 
                icon={economy} 
                points={props.score.economy}
                color="#1B16FF"
                height="55px"
                rowHeight="70px"
            ></ScopeFinalPoints>

            <div className="Game-over-text">{i18next.t("This is your final score. What kind of future will you have designed?")}</div>

            <button className="View-results-button" onClick={() => props.onClick()}>{i18next.t("View result")}</button>
            
            <div className='App-game-over-footer'>
                <div className="Logo-atelier-div">
                    <img src={atelier} className="Logo-atelier" alt="logo" />
                    <img src={deustotech} className="Logo-deustotech" alt="logo" />
                </div>
            </div>
        </div>
    )    
}

export default withTranslation()(GameOverScreen);