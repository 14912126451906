import React from "react";

function ScopeFinalPoints(props){
    const scopeStyle = {
        color: props.color,
    };

    const scopeDivStyle = {
        height: props.height,
    };

    const scopeRowStyle = {
        height: props.rowHeight,
    }

    return (
        <div className="Scope-points-div" style={scopeRowStyle}>
            <img className="Scope-points-icon" src={props.icon} alt="" style={scopeDivStyle} />
            <div className="Scope-points-title" style={scopeDivStyle}><div style={scopeStyle}>{props.title}:</div></div>
            <div className="Scope-points-value" style={scopeStyle}>{props.points}%</div>
        </div>
    )
}

export default ScopeFinalPoints;