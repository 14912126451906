import Modal from 'react-bootstrap/Modal';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import Parser from 'html-react-parser';

import crowd from './../img/crowd.png';
import forest from './../img/forest.png';
import economy from './../img/economy.png';
import innovation from './../img/innovation.png';

function ObtainedPointsModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <div className='Obtained-points-modal-body'>
                <p>{ i18next.t("You have obtained") }</p>
                <p><img className="Points-modal-icon" src={forest} alt="Environment icon"/><span className='Environment-text'>{ props.points.environment }</span> { Parser(i18next.t("points of environment")) }</p>
                <p><img className="Points-modal-icon" src={economy} alt="Economy icon"/><span className='Economy-text'>{ props.points.economy }</span> { Parser(i18next.t("points of economy")) }</p>
                <p><img className="Points-modal-icon" src={crowd} alt="Society icon"/><span className='Society-text'>{ props.points.society }</span> { Parser(i18next.t("points of society")) }</p>
                <p><img className="Points-modal-icon" src={innovation} alt="Innovation icon"/><span className='Innovation-text'>{ props.points.innovation }</span> { Parser(i18next.t("points of innovation")) }</p>
            </div>
            <div className='Obtained-points-modal-footer'>
                <button className="Intro-button-right" onClick={ props.onHide }>{i18next.t("Continue")}</button>
            </div>

        </Modal>
    );
}

export default withTranslation()(ObtainedPointsModal);