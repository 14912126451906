import React from "react";

function UserActionScore(props){
    const scopeStyle = {
        color: props.color,
    };

    const scopeDivStyle = {
        height: props.height,
    };

    const scopeRowStyle = {
        height: props.rowHeight,
        display: "flex"
    }

    return (
        <div className="Scope-points-div" style={scopeRowStyle}>
            <img className="Scope-points-icon" src={props.icon} alt="" style={scopeDivStyle} />
            <div className="Scope-points-value" style={scopeStyle}>{props.points}%</div>
        </div>
    )
}

export default UserActionScore;