import React, {useState, useEffect, useRef} from 'react';
//import {Link} from 'react-router-dom';

import logo_es from "./../img/logo-home-es.png"
import logo_eus from "./../img/logo-home-eus.png"
import logo_en from "./../img/logo-home-en.png"

import Footer from './Footer';

import LanguageSelect from './LanguageSelect';

import i18n from './../i18n';
import { withTranslation } from 'react-i18next';

import { useNavigate } from "react-router-dom"

const HomeScreen = ({t}) => {  
  const navigate = useNavigate();

  const loadLanguage = () => {
    var appLanguage = localStorage.getItem("lang");
    if(!appLanguage){
      appLanguage = "es";
    }
    return appLanguage;
  }

  const handlePlayClick = () => {
    navigate('/intro', { replace: true });
  }

  var storedLanguage = loadLanguage();
  const [selectedLang, setSelectedLang] = useState(storedLanguage);

  let logo = useRef(logo_es);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
    if(selectedLang === "es"){
      logo.current = logo_es;
    } else if(selectedLang === "eus"){
      logo.current = logo_eus;
    } else {
      logo.current = logo_en;
    }
  }, [selectedLang]);

  const handleOnChangeLanguage = (event) =>{
    var lang = event.target.value;
    setSelectedLang(lang);
    localStorage.setItem("lang", lang);
  }

  return (

    <div className="App">
      <header className="App-intro-header">
        <img src={logo.current} className="App-logo" alt="logo" />
        <div className="App-home-buttons-div">
          <button className="App-home-button" onClick={handlePlayClick}>{t("Play")}</button>
          {/*<button className="App-home-button"><Link to="/profile" className='App-home-button-link'>{t("Profile")}</Link></button> */}
        </div>
      </header>
      <Footer></Footer>
      
      <LanguageSelect value={selectedLang} onChange={(event) => handleOnChangeLanguage(event)}></LanguageSelect>
    </div>
    
  );

}
  
export default withTranslation()(HomeScreen);
