import React from 'react';
import {Link} from 'react-router-dom';

//import intro3 from './../img/intro3.png';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function IntroText(props) {
    if(props.page < 5){
        return (
            <div className="Intro-text-div">
                <div className="Intro-text">
                    { props.text }
                </div>
    
                <button className="Intro-button-left"><Link to="/game" className='App-intro-button-link'>{i18next.t("Skip")}</Link></button>
                <button className="Intro-button-right" onClick={ props.onClick }>{i18next.t("Next")}</button>
            </div>
        );
    } else {        
        return (
            <div className="Intro-text-div">
                <div className="Intro-text">
                    { props.text }
                </div>

                <button className="Intro-button-right"><Link to="/game" className='App-intro-button-link'>{i18next.t("Play")}</Link></button>
            </div>
        );
    }

    /*if(props.page < 3){
        return (
            <div className="Intro-text-div">
                <div className="Intro-text">
                    { props.text }
                </div>

                <button className="Intro-button-left"><Link to="/game" className='App-intro-button-link'>{i18next.t("Skip")}</Link></button>
                <button className="Intro-button-right" onClick={ props.onClick }>{i18next.t("Next")}</button>
            </div>
        );
    } else if(props.page === 3){
        return (
            <div className="Intro-text-div">
                <div className="Intro-text-left">
                    { props.text }
                    <div className="Intro-text-bullet-point">
                        <lu>
                            <li>{i18next.t("Environment")}</li>
                            <li>{i18next.t("Economy")}</li>
                            <li>{i18next.t("Society")}</li>
                            <li>{i18next.t("Technological innovation")}</li>
                        </lu>
                    </div>
                    <div className='Intro-board-icon-div'>
                        <img src={intro3} alt="icon" className='Intro-board-icon'/>
                    </div>
                </div>

                <button className="Intro-button-left"><Link to="/game" className='App-intro-button-link'>{i18next.t("Skip")}</Link></button>
                <button className="Intro-button-right" onClick={ props.onClick }>{i18next.t("Next")}</button>
            </div>
        );
    } else {
        return (
            <div className="Intro-text-div">
                <div className="Intro-text-left">
                    { props.text }
                    <div className="Intro-text-bullet-point">
                        <lu>
                            <li>{i18next.t("Will you favor sustainable growth?")}</li>
                            <li>{i18next.t("Will you contribute to climate change mitigation?")}</li>
                            <li>{i18next.t("Will you invest in technologically advanced solutions?")}</li>
                            <li>{i18next.t("Will you take into account the citizenship?")}</li>
                        </lu>
                    </div>
                </div>

                <button className="Intro-button-right"><Link to="/game" className='App-intro-button-link'>{i18next.t("Play")}</Link></button>
            </div>
        );
    }*/
}

export default withTranslation()(IntroText);