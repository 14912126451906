import React from "react";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function DilemaOptionInfo(props){
    var appLanguage = localStorage.getItem("lang");

    if(props.active) {
        if(appLanguage === "eus"){
            return (
                <div className="Dilema-option-info-div">
                    <div className="Option-label">{props.number}. {i18next.t("Option")}:</div>
                    <p className="Dilema-option-description">{props.text}</p>
                </div>
            )
        } else {
            return (
                <div className="Dilema-option-info-div">
                    <div className="Option-label">{i18next.t("Option")} {props.number}:</div>
                    <p className="Dilema-option-description">{props.text}</p>
                </div>
            )
        }
    }
}

export default withTranslation()(DilemaOptionInfo);