import React from "react";

import left from './../img/left.png';
import right from './../img/right.png';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function GameAction(props) {
    const style = {
        display: "inline-block"
    }

    if(props.controls === "none"){
        return(
            <div className="Game-actions-div">
                <label className="Game-actions-label">{i18next.t("Actions")} {props.value}/{props.actions}</label>
            </div>
        )
    } else {
        if(props.controls === "both"){
            return(
                <div className="Game-actions-div">
                    <img src={left} className="Game-actions-control-icon" alt="" style={style} onClick={(event) => props.onClick("left")}/>
                    <label className="Game-actions-label"  style={style}>{i18next.t("Actions")} {props.value}/{props.actions}</label>
                    <img src={right} className="Game-actions-control-icon" alt="" style={style} onClick={(event) => props.onClick("right")}/>
                </div>
            )
        } else if(props.controls === "right"){
            return(
                <div className="Game-actions-div">
                    <div className="Game-actions-control-icon" style={style}/>
                    <label className="Game-actions-label"  style={style}>{i18next.t("Actions")} {props.value}/{props.actions}</label>
                    <img src={right} className="Game-actions-control-icon" alt="" style={style} onClick={(event) => props.onClick("right")}/>
                </div>
            )
        } else if(props.controls === "left"){
            return(
                <div className="Game-actions-div">
                    <img src={left} className="Game-actions-control-icon" alt="" style={style} onClick={(event) => props.onClick("left")}/>
                    <label className="Game-actions-label"  style={style}>{i18next.t("Actions")} {props.value}/{props.actions}</label>
                    <div className="Game-actions-control-icon" style={style}/>
                </div>
            )
        }
    }

}

export default withTranslation()(GameAction);