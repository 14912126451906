import React, { useState } from 'react';

import GameProcess from './GameProcess';
import DilemaScreen from './DilemaScreen';
import EventScreen from './EventScreen';
import GameOverScreen from './GameOverScreen';
import ResultScreen from './ResultScreen';

import gameData from './../data/atelier_sg_data_v2_TRABAJO.json';

function GameScreen() {
    const totalActions = 10;
    const eventProbability = 20;

    const [actionsCount, setActionsCount] = useState(0);
    const [view, setView] = useState("process");
    const [showResult, setShowResult] = useState(false);

    const [gamePoints, setGamePoints] = useState({
        environment: 50,
        society: 50,
        innovation: 50,
        economy: 50
    });

    const [lastActionPoints, setLastActionPoints] = useState({
        environment: 0,
        society: 0,
        innovation: 0,
        economy: 0
    });

    var dilemaOptionsOrgByChallenge = getDilemaOptionsByChallenge(gameData.dilemaOptionData);
    const [dilemaOptionsByChallenge] = useState(dilemaOptionsOrgByChallenge);
    
    var actionsConfig = initializeGameActionTypeList();
    const [gameActionsTypeList] = useState(actionsConfig);
    var gameActionListConfig = initializeGameActionList();
    const [gameActionsList] = useState(gameActionListConfig);
    
    const [userActionList] = useState([]);

    //console.log(gameActionsTypeList);
    console.log(gameActionsList);

    if(actionsCount < totalActions){
        if(view === "process"){
            return (
                <GameProcess 
                    score={gamePoints} 
                    action={gameActionsTypeList[actionsCount]} 
                    actionsCount={actionsCount} 
                    totalActions={totalActions} 
                    onClick={(e) => handleGoActionClick(e)}
                    lastActionPoints={lastActionPoints}
                    userActionList={userActionList}
                ></GameProcess>
            );   
        } else if (view === "dilema"){
            return(
                <DilemaScreen dilema={gameActionsList[actionsCount]} onClick={(points, number) => handleDilemaDecisionClick(points, number)}></DilemaScreen>
            )
        } else if (view === "event"){
            return(
                <EventScreen event={gameActionsList[actionsCount]} onClick={(actionPoints) => handleEventDecisionClick(actionPoints)}></EventScreen>
            )
        }
    } else {
        if(showResult){
            return (
                <ResultScreen 
                    score={gamePoints}    
                    actionsCount={actionsCount}                                   
                    userActionList={userActionList}
                ></ResultScreen>
            )
        } else {
            return (
                <GameOverScreen 
                    score={gamePoints} 
                    onClick={(e) => handleShowResultClick(e)}  
                    actionsCount={actionsCount}                   
                    userActionList={userActionList}
                ></GameOverScreen>
            )
        }
    }
   

    // Event probability: 20%
    function initializeGameActionTypeList(){
        let actionTypes = [];
        for(var i=0; i<totalActions; i++){
            if(isEvent()){
                actionTypes.push("event");
            } else {
                actionTypes.push("dilema");
            }
        }
        return actionTypes;
    }

    function getDilemaOptionsByChallenge(dilemaOptionData){
        let challengeIds = [];
        let challengeOptions = [];

        let dilemaOptionsByChallenge = [];

        dilemaOptionData.forEach(optionElement => {
            let id = optionElement.challenge.id;
            if(challengeIds.includes(id)){
                let index = null;
                let counter = 0;

                while(index == null && counter < challengeIds.length){
                    if(challengeIds[counter] === id){
                        index = counter;
                    }
                    counter++;
                }
                challengeOptions[index].push(optionElement);
            } else {
                challengeIds.push(id);
                challengeOptions.push([optionElement]);
            }
        });

        for(var i=0; i<challengeIds.length; i++){
            dilemaOptionsByChallenge.push([]);
        }

        //console.log("dilemaOptionsByChallenge");
        //console.log(dilemaOptionsByChallenge);

        challengeIds.forEach(id => {
            let options = challengeOptions[challengeIds.indexOf(id)];

            let index = Number(id)-1;
            dilemaOptionsByChallenge[index].push(options);
        });

        console.log("dilemaOptionsByChallenge");
        console.log(dilemaOptionsByChallenge);

        return dilemaOptionsByChallenge;
    }

    function initializeGameActionList(){
        let gameActionsDataList = [];

        let eventDataAux = JSON.parse(JSON.stringify(gameData.eventData));
        //let dilemaOptionDataAux = JSON.parse(JSON.stringify(gameData.dilemaOptionData));

        let challengeDilemaOptionDataAux = JSON.parse(JSON.stringify(dilemaOptionsByChallenge));

        gameActionsTypeList.forEach(actionType => {
            let actionToInclude;
            if(actionType === "event"){
                actionToInclude = getRandomEvent(eventDataAux);
            } else {
                actionToInclude = getRandomDilema(gameData.dilemaData);
                let challengeIndex = Number(actionToInclude.challenge.id)-1;
                if(challengeDilemaOptionDataAux[challengeIndex][0].length < 3){
                    challengeDilemaOptionDataAux[challengeIndex][0] = JSON.parse(JSON.stringify(dilemaOptionsByChallenge[challengeIndex][0]));
                }
                actionToInclude.options = getDilemaOptions(challengeDilemaOptionDataAux[challengeIndex][0]);
            }
            gameActionsDataList.push(actionToInclude);
        });

        return gameActionsDataList;
    }

    function getRandomEvent(eventData){
        let selectedEventIndex = randomNumber(0, eventData.length);
        return eventData.splice(selectedEventIndex, 1)[0];
    }

    function getRandomDilema(dilemaData){
        let selectedDilemaIndex = randomNumber(0, dilemaData.length);
        return dilemaData[selectedDilemaIndex];
    }

    function getDilemaOptions(dilemaOptionData){
        let dilemaOptions = [];
        for(var i=0; i<3; i++){
            dilemaOptions.push(getRandomDilemaOption(dilemaOptionData));
        }
        return dilemaOptions;
    }

    function getRandomDilemaOption(dilemaOptionData){
        let selectedDilemaOptionIndex = randomNumber(0, dilemaOptionData.length);
        return dilemaOptionData.splice(selectedDilemaOptionIndex, 1)[0];
    }

    function randomNumber(min, max) {
        return parseInt(Math.random() * (max - min) + min);
    }

    function isEvent(){
        var num = Math.random();
        var isEvent = false;
        if(num < eventProbability/100) {
            isEvent = true;  
        } 
        return isEvent;
    }

    function handleGoActionClick(actionType){
        setView(actionType);
    }

    function handleDilemaDecisionClick(actionPoints, selectedOptionNumber){
        //console.log("handleDilemaDecisionClick");
        //console.log(actionPoints);
        addUserDilemaDecision(gameActionsList[actionsCount], selectedOptionNumber);

        setActionsCount(actionsCount + 1);
        setView("process");

        updateGamePoints(actionPoints);
        setLastActionPoints(actionPoints);
    }

    function handleEventDecisionClick(actionPoints){
        //console.log(handleEventDecisionClick);
        //console.log(actionPoints);
        addUserEventDecision(gameActionsList[actionsCount]);

        setActionsCount(actionsCount + 1);
        setView("process");

        updateGamePoints(actionPoints);
        setLastActionPoints(actionPoints);
    }

    function addUserEventDecision(event){
        let eventAux = JSON.parse(JSON.stringify(event));
        eventAux.actionType = "event";
        userActionList.push(eventAux);
    }

    function addUserDilemaDecision(dilema, selectedOptionNumber){
        let dilemaAux = JSON.parse(JSON.stringify(dilema));
        dilemaAux.selectedOptionNumber = selectedOptionNumber;
        dilemaAux.actionType = "dilema";
        userActionList.push(dilemaAux);
    }

    function updateGamePoints(actionPoints){
        console.log("actionPoints:");
        console.log(actionPoints);
        var environmentScore = gamePoints.environment + parseInt(actionPoints.environment);
        var societyScore = gamePoints.society + parseInt(actionPoints.society);
        var innovationScore = gamePoints.innovation + parseInt(actionPoints.innovation);
        var economyScore = gamePoints.economy + parseInt(actionPoints.economy);
        
        setGamePoints(({
            environment: environmentScore,
            society: societyScore,
            innovation: innovationScore,
            economy: economyScore
        }));        
    }

    function handleShowResultClick(){
        setShowResult(true);
    }
}

export default GameScreen;