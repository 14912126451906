import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import atelier from './../img/atelier.jpg';
import deustotech from './../img/deustotech.png';

import crowd from './../img/crowd.png';
import forest from './../img/forest.png';
import economy from './../img/economy.png';
import innovation from './../img/innovation.png';
import economicIsland from './../img/economic-island.png';
import environmentalIsland from './../img/environmental-island.png';
import innovationIsland from './../img/innovation-island.png';
import socialIsland from './../img/social-island.png';

import ScopeFinalPoints from "./ScopeFinalPoints";
import UserActionsModal from "./UserActionsModal";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import Parser from 'html-react-parser';

function ResultScreen(props){
    const [showPoints, setShowPoints] = useState(false);

    const userActionList = useRef([]);
    const actionsCount = useRef(0);

    useEffect(() => {
        userActionList.current = props.userActionList;
        actionsCount.current = props.actionsCount;
    }, [props.userActionList, props.actionsCount]);

    //const textStyle = useRef({});

    let result = useRef(getResult());

    let text1DisplayStyle =  {display: "initial"};
    let text2DisplayStyle = {display: "initial"};
    let warnings1DisplayStyle =  {display: "initial"};
    let warnings2DisplayStyle = {display: "initial"};

    if (result.current.text1 === "") {
        text1DisplayStyle =  {display: "none"};
    }
    if (result.current.text2 === "") {
        text2DisplayStyle =  {display: "none"};
    }
    if (result.current.warnings1 === "") {
        warnings1DisplayStyle =  {display: "none"};
    }
    if (result.current.warnings2 === "") {
        warnings2DisplayStyle =  {display: "none"};
    }

    return (
        <div className="App">
            <UserActionsModal actionsCount={actionsCount.current} userActionList={userActionList.current} last={false} resultScreen={true}></UserActionsModal>

            <label className="Game-over-label">{i18next.t("Your island")}</label>

            <PointsResults active={showPoints} score={props.score}></PointsResults>
            <IslandResults active={!showPoints} img={result.current.island}></IslandResults>

            <ResultViewOptions showPoints={showPoints} onClick={() => handleResultOptionClick()}></ResultViewOptions>

            <div className="Game-result-text">{Parser(result.current.globalScore)}</div>
            <div className="Game-result-text" style={text1DisplayStyle}>{Parser(result.current.text1)}</div>
            <div className="Game-result-text" style={text2DisplayStyle}>{Parser(result.current.text2)}</div>
            <div className="Game-result-text" style={warnings1DisplayStyle}>{Parser(result.current.warnings1)}</div>
            <div className="Game-result-text" style={warnings2DisplayStyle}>{Parser(result.current.warnings2)}</div>

            <button className="Finish-button"><Link to="/" className="Finish-button">{i18next.t("Finish")}</Link></button>
            
            <div className='App-game-over-footer'>
                <div className="Logo-atelier-div">
                    <img src={atelier} className="Logo-atelier" alt="logo" />
                    <img src={deustotech} className="Logo-deustotech" alt="logo" />
                </div>
            </div>
        </div>
    )

    function getResult(){
        let img;
        let resultTextBlock1;
        let resutlTextBlock2 = "";
        let negativeResultsWarn = "";
        let mostNegativeWarn = "";

        // Obtained scores conclusion text
        let scoresArray = [props.score.environment, props.score.economy, props.score.society, props.score.innovation];       
        let secondScoresArray; 
        let maxPunctuation = Math.max(...scoresArray);
        let maxPunctuationScope;
        
        if(props.score.environment === maxPunctuation){
            img = environmentalIsland;
            resultTextBlock1 = i18next.t("Environment island result");
            secondScoresArray = [props.score.economy, props.score.society, props.score.innovation];       
            maxPunctuationScope = "environment";
        } else if(props.score.economy === maxPunctuation){
            img = economicIsland;
            resultTextBlock1 = i18next.t("Economy island result");
            secondScoresArray = [props.score.environment, props.score.society, props.score.innovation];       
            maxPunctuationScope = "economy";
        } else if(props.score.society === maxPunctuation){
            img = socialIsland;
            resultTextBlock1 = i18next.t("Society island result");
            secondScoresArray = [props.score.environment, props.score.economy, props.score.innovation];    
            maxPunctuationScope = "society";   
        } else {
            img = innovationIsland;
            resultTextBlock1 = i18next.t("Innovation island result");
            secondScoresArray = [props.score.environment, props.score.economy, props.score.society];   
            maxPunctuationScope = "innovation";    
        }

        // Second max punctuation (not less than 50 points and not less than 10% than the maximum one)
        let secondMaxPunctuation = Math.max(...secondScoresArray);
        let resutlTextBlock2_scopes = []

        if(secondMaxPunctuation >= 50 && secondMaxPunctuation >= (maxPunctuation * 0.9)){
            // We recreate the scores array without the maximum punctuation scope points
            if(props.score.environment === secondMaxPunctuation && maxPunctuationScope !== "environment"){
                resutlTextBlock2_scopes.push(i18next.t("Environmental Sustainability"));
            } 
            if(props.score.society === secondMaxPunctuation && maxPunctuationScope !== "society"){
                resutlTextBlock2_scopes.push(i18next.t("Social Configuration"));
            } 
            if(props.score.economy === secondMaxPunctuation && maxPunctuationScope !== "economy"){
                resutlTextBlock2_scopes.push(i18next.t("Economic Dynamism"));
            }             
            if (props.score.innovation === secondMaxPunctuation && maxPunctuationScope !== "innovation"){
                resutlTextBlock2_scopes.push(i18next.t("Innovative Ecosystem"));
            }

            let resutlTextBlock2_scopesText = "";
            for(var i=0; i<resutlTextBlock2_scopes.length; i++){
                let scope = resutlTextBlock2_scopes[i];

                if (resutlTextBlock2_scopesText === ""){
                    resutlTextBlock2_scopesText = scope;
                } else if (i !==0 && i === resutlTextBlock2_scopes.length - 1){
                    resutlTextBlock2_scopesText += " " + i18next.t("and") + " ";
                } else {
                    resutlTextBlock2_scopesText += ", " + scope;
                }
            }

            resutlTextBlock2 = i18next.t("Result text second block") + resutlTextBlock2_scopesText + ".";
        }

        // Negative punctuations
        let negativeValueScopes = [];
        let mostNegative = 0;
        if(props.score.environment < 0){
            negativeValueScopes.push(i18next.t("Environmental Sustainability"));
            if(mostNegative > props.score.environment){
                mostNegativeWarn = i18next.t("Environment negative score");
                mostNegative = props.score.environment;
            }
        } 
        if(props.score.society < 0){
            negativeValueScopes.push(i18next.t("Social Configuration"));
            if(mostNegative > props.score.society){
                mostNegativeWarn = i18next.t("Society negative score");
                mostNegative = props.score.society;
            }
        } 
        if(props.score.economy < 0){
            negativeValueScopes.push(i18next.t("Economic Dynamism"));
            if(mostNegative > props.score.economy){
                mostNegativeWarn = i18next.t("Economy negative score");
                mostNegative = props.score.economy;
            }
        }             
        if (props.score.innovation < 0){
            negativeValueScopes.push(i18next.t("Innovative Ecosystem"));
            if(mostNegative > props.score.innovation){
                mostNegativeWarn = i18next.t("Innovation negative score");
                mostNegative = props.score.innovation;
            }
        }

        if(negativeValueScopes.length > 0){
            for(var index=0; index<negativeValueScopes.length; index++){
                let scope = negativeValueScopes[index];

                if (negativeResultsWarn === ""){
                    negativeResultsWarn += scope;
                } else if (i !== 0 && i === resutlTextBlock2_scopes.length - 1){
                    negativeResultsWarn += " " + i18next.t("and") + " ";
                } else {
                    negativeResultsWarn += ", " + scope;
                }
            }

            negativeResultsWarn = i18next.t("Negative punctuation") + negativeResultsWarn + ".";
        }
        

        // Global score text
        let globalScoreText = i18next.t("Global score");
        let totalPoints = props.score.environment + props.score.economy + props.score.society + props.score.innovation;
        let positiveScopes = 4 - negativeValueScopes.length;
        globalScoreText = globalScoreText.replace("<score>", totalPoints);
        globalScoreText = globalScoreText.replace("<positive_assessment>", positiveScopes);

        // Result
        let result = {
            island: img,
            globalScore: globalScoreText,
            text1: resultTextBlock1,
            text2: resutlTextBlock2,
            warnings1: negativeResultsWarn,
            warnings2: mostNegativeWarn
        }
        return result;
    }

    function handleResultOptionClick(){
        setShowPoints(!showPoints);
    }

    function ResultViewOptions(props){
    
        if(props.showPoints){
            return(
                <div className="Result-view-options-div">
                    <label className="Result-option-link" onClick={() => props.onClick()}>{i18next.t("Island")}</label> | <label>{i18next.t("Scores")}</label>
                </div>
            )
        } else {
            return(
                <div className="Result-view-options-div">
                    <label>{i18next.t("Island")}</label> | <label className="Result-option-link" onClick={() => props.onClick()}>{i18next.t("Scores")}</label>
                </div>
            )
        }         
    }

    function PointsResults(props){
        if(props.active){
            return (
                <div className="App">
                    <UserActionsModal userActionList={props.userActionList} resultScreen={false}></UserActionsModal>

                    <ScopeFinalPoints 
                        title={i18next.t("Environment")} 
                        icon={forest} 
                        points={props.score.environment}
                        color="#1B5F2A"
                        height="40px"
                        rowHeight="50px"
                    ></ScopeFinalPoints>
                    <ScopeFinalPoints 
                        title={i18next.t("Society")} 
                        icon={crowd} 
                        points={props.score.society}
                        color="#8B1772"
                        height="40px"
                        rowHeight="50px"
                    ></ScopeFinalPoints>
                    <ScopeFinalPoints 
                        title={i18next.t("Innovation")} 
                        icon={innovation} 
                        points={props.score.innovation}
                        color="#877C16"
                        height="40px"
                        rowHeight="50px"
                    ></ScopeFinalPoints>
                    <ScopeFinalPoints 
                        title={i18next.t("Economy")} 
                        icon={economy} 
                        points={props.score.economy}
                        color="#1B16FF"
                        height="40px"
                        rowHeight="50px"
                    ></ScopeFinalPoints>
                </div>
            )
        }
    }

    function IslandResults(props){
        if(props.active){
            return (
                <div>
                    <img className="Result-img" src={props.img} alt=""/>
                </div>
            )
        }
    }
    
}

export default withTranslation()(ResultScreen);