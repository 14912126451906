import React from "react";

import buildings from './../img/buildings.png';
import atelier from './../img/atelier.jpg';
import deustotech from './../img/deustotech.png';


function Footer(props) {

    return (
        /*<div className='App-intro-footer'>
            <img src={buildings} className="App-footer-img" alt="logo" />
            <div className="Logo-atelier-div">
                <img src={atelier} className="Logo-atelier" alt="logo" />
                <img src={deustotech} className="Logo-deustotech" alt="logo" />
            </div>
        </div>*/

        <>
            <div className="Footer-img-div">
                <img src={buildings} className="App-footer-img" alt="logo" />
            </div>
            <div className="Footer-logos-div">
                <img src={atelier} className="Logo-atelier" alt="logo" />
                <img src={deustotech} className="Logo-deustotech" alt="logo" />
            </div>
        </>
    )

}

export default Footer;