import React, { useState } from 'react';

import sun from './../img/sun.png';

import Footer from './Footer';

import IntroText from './IntroText';
import QuestionIcon from './QuestionIcon';
import ThinkingIcon from './ThinkingIcon';

import { withTranslation } from 'react-i18next';

function IntroScreen({t}) {
    // Introduction has 4 pages
    const [page, setPage] = useState(1);

    const introTexts = {
        1: t("Intro1"),
        2: t("Intro2"),
        3: t("Intro3"),
        4: t("Intro4"),
        5: t("Intro5")
    }

    return (
        <div className="App">
            <header className="App-intro-header">
                <img src={sun} className="App-logo-intro" alt="logo" />
            </header>
            <IntroText text={introTexts[page]} page={page} onClick={() => handleNextClick()}></IntroText>
            <div className='Intro-question-icon-div'>
                <QuestionIcon activate={page === 4}></QuestionIcon>
            </div>
            <div className='Intro-thinking-icon-div'>
                <ThinkingIcon activate={page === 3}></ThinkingIcon>
            </div>         

            <Footer></Footer>   
        </div>
    );

    function handleNextClick() {
        setPage(page + 1);
    }
}

export default withTranslation()(IntroScreen);