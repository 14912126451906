import React from "react";

import level from './../img/level.png';

import AnimatedNumber from "animated-number-react";

function GameScope(props) {

    const labelStyle = {
        color: props.color,
    };

    var levelHeight;
    if(props.value > 100){
        levelHeight = "75px";
    } else if(props.value >=0){
        levelHeight = 75 * props.value / 100;
        levelHeight += "px";
    } else {
        levelHeight = "0px";
    }

    const levelBarStyle = {
        background: props.color,
        height: levelHeight
    };

    const formatValue = (value) => value.toFixed(0);

    if(props.location === "top") {
        if(props.position === "left") {
            return (
                <div className="App-game-scope">
                    <label color={props.color} className="App-game-scope-title-top" style={labelStyle}>{props.title}</label>
                    <img src={level} className="App-game-scope-level-left" alt=""/>
                    <div className="Scope-level-value-bar-left" style={levelBarStyle}></div>
                    <img src={props.icon} className="App-game-scope-icon-left" alt=""></img>
                    <label className="App-game-scope-value-left-top" style={labelStyle}><AnimatedNumber value={props.value} formatValue={formatValue}></AnimatedNumber>%</label>
                </div>
            )
        } else {
            return (
                <div className="App-game-scope">
                    <label color={props.color} className="App-game-scope-title-top" style={labelStyle}>{props.title}</label>
                    <img src={level} className="App-game-scope-level-right" alt=""/>
                    <div className="Scope-level-value-bar-right" style={levelBarStyle}></div>
                    <img src={props.icon} className="App-game-scope-icon-right" alt=""></img>
                    <label className="App-game-scope-value-right-top" style={labelStyle}><AnimatedNumber value={props.value} formatValue={formatValue}></AnimatedNumber>%</label>
                </div>
            )
        }
    } else {
        if(props.position === "left") {
            return (
                <div className="App-game-scope">
                    <label className="App-game-scope-value-left-bottom" style={labelStyle}><AnimatedNumber value={props.value} formatValue={formatValue}></AnimatedNumber>%</label>
                    <img src={level} className="App-game-scope-level-left" alt=""/>
                    <div className="Scope-level-value-bar-left" style={levelBarStyle}></div>
                    <img src={props.icon} className="App-game-scope-icon-left" alt=""></img>
                    <label color={props.color} className="App-game-scope-title-bottom" style={labelStyle}>{props.title}</label>
                </div>
            )
        } else {
            return (
                <div className="App-game-scope">
                    <label className="App-game-scope-value-right-bottom" style={labelStyle}><AnimatedNumber value={props.value} formatValue={formatValue}></AnimatedNumber>%</label>
                    <img src={level} className="App-game-scope-level-right" alt=""/>
                    <div className="Scope-level-value-bar-right" style={levelBarStyle}></div>
                    <img src={props.icon} className="App-game-scope-icon-right" alt=""></img>
                    <label color={props.color} className="App-game-scope-title-bottom" style={labelStyle}>{props.title}</label>
                </div>
            )
        }
    }

}

export default GameScope;