import React from "react";

import informacion from './../img/informacion.png';
import one from './../img/one.png';
import two from './../img/two.png';
import three from './../img/three.png';

function DilemaOption(props) {
    let points = props.points;
    let number = props.number;

    return (
        <div className="Dilema-option-div">
            <DilemaNumber number={number}></DilemaNumber>
            <div className="Dilema-option-text-div" onClick={(event) => props.onClick(points, number)}><div className="Dilema-option-text">{props.text}</div></div>
            <img className="Dilema-option-info" src={informacion} alt="" onClick={(event) => props.onAuxClick(props.number)} />
        </div>
    )

    function DilemaNumber(props){
        if(props.number === "1"){
            return (
                <img className="Dilema-number" src={one} alt=""></img>
            )
        } else if(props.number === "2"){
            return (
                <img className="Dilema-number" src={two} alt=""></img>
            )
        } else {
            return (
                <img className="Dilema-number" src={three} alt=""></img>
            )
        }
    }
}

export default DilemaOption;