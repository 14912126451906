import React, {useState, useEffect} from "react";

//import buildings from './../img/buildings.png';
//import atelier from './../img/atelier.jpg';

import GameAction from "./GameActions";
import GameActionInfo from "./GameActionInfo";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function UserActions(props) {
    const [actionControl, setActionControl] = useState("none");

    const [actionList, setActionList] = useState([]);

    let initialActionNumber;
    if(props.last){
        initialActionNumber = props.userActionList.length;
    } else {
        initialActionNumber = 1;
    }

    const [showingActionInfo, setShowingActionInfo] = useState({
        actionNumber: initialActionNumber,
        selectedAction: props.userActionList[props.userActionList.length-1]
    });

    const handleOnClickArrow = (event) => {
        //console.log(event);
        let newActionNumber;
        if(event === "right"){ 
            newActionNumber = showingActionInfo.actionNumber + 1;
        } else if(event === "left"){
            newActionNumber = showingActionInfo.actionNumber - 1;
        }
        setShowingActionInfo({
            actionNumber: newActionNumber,
            selectedAction: actionList[newActionNumber-1]
        });

        console.log("actionNumber: " + newActionNumber);
        console.log(actionList[newActionNumber-1]);
    }
 
    useEffect(() => {      
        console.log(props.userActionList);
        setActionList(props.userActionList);  

        // eslint-disable-next-line eqeqeq
        if(showingActionInfo.actionNumber === 1 && props.actionsCount == "1"){
            setActionControl("none");
        } else if(showingActionInfo.actionNumber === 1){
            setActionControl("right");
        // eslint-disable-next-line eqeqeq
        } else if(showingActionInfo.actionNumber == props.actionsCount){
            setActionControl("left");
        } else {
            setActionControl("both");
        }
    }, [actionList, showingActionInfo.actionNumber, props.actionsCount, props.userActionList]);

    const continueMarginBottomStyle = {
        marginBottom: '40px'
    }

    return (
        <div className="App">
            <GameAction value={showingActionInfo.actionNumber} actions={props.actionsCount} controls={actionControl} onClick={handleOnClickArrow}></GameAction>

            <GameActionInfo action={showingActionInfo.selectedAction}></GameActionInfo>

            <button className="Event-continue-button" style={continueMarginBottomStyle} onClick={(event) => props.onClick()}>{i18next.t("Continue")}</button>

            {/*<div className='App-modal-footer'>
                <img src={buildings} className="App-modal-img" alt="" />
                <div className="Logo-atelier-div">
                    <img src={atelier} className="Logo-atelier" alt="atelier" />
                </div>
            </div>*/}
        </div>
    )
}

export default withTranslation()(UserActions);